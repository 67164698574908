var render = function render(){var _vm=this,_c=_vm._self._c;return _c('form',{ref:"profile_form",staticClass:"add-form",on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;$event.preventDefault();return (() => {}).apply(null, arguments)}}},[(_vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_ORGANIZATIONS))?_c('div',{staticClass:"form-wrapper"},[_c('base-input',{attrs:{"label":`${_vm.$t('COMMON.ORGANIZATION')} (*)`,"placeholder":_vm.$t('COMMON.ORGANIZATION')}},[_c('organization-selector',{attrs:{"allowNone":true,"organization":_vm.purchasesInvoice.organization.id,"filterable":true,"showAll":false,"disabled":!!_vm.purchasesInvoice.id},on:{"organizationChanged":(organizationId) => {
            _vm.purchasesInvoice.organization.id = organizationId;
            _vm.purchasesInvoice.allowedLocations = [];
            _vm.purchasesInvoice.purchasesOrder.id = null;
            _vm.purchasesInvoice.issuer.id = null;
            _vm.onFormChanged();
          }}})],1),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.organization}})],1):_vm._e(),(_vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_LOCATIONS))?_c('div',{staticClass:"form-wrapper"},[_c('base-input',{attrs:{"label":`${_vm.$t('COMMON.LOCATION')}`,"placeholder":_vm.$t('COMMON.LOCATION')}},[_c('locations-selector',{attrs:{"locations":_vm.purchasesInvoice.allowedLocations,"filterable":true,"showAll":false,"multiple":true,"organization":_vm.purchasesInvoice.organization.id},on:{"locationsChanged":(locations) => {
            _vm.purchasesInvoice.allowedLocations = locations;
            _vm.onFormChanged();
          }}})],1),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.location}})],1):_vm._e(),_c('h2',{staticClass:"col-12 mt-0 mb-0"},[_vm._v(_vm._s(_vm.$t("COMMON.NOTE")))]),_c('div',{staticClass:"form-wrapper full"},[_c('html-editor',{on:{"change":function($event){return _vm.onFormChanged()}},model:{value:(_vm.purchasesInvoice.excerpt),callback:function ($$v) {_vm.$set(_vm.purchasesInvoice, "excerpt", $$v)},expression:"purchasesInvoice.excerpt"}}),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.excerpt}})],1),_c('div',{staticClass:"submit-wrapper"},[_c('base-button',{staticClass:"btn btn-sm elite-submit",attrs:{"type":"button","native-type":"submit","disabled":_vm.loading}},[(_vm.loading)?_c('i',{staticClass:"fas fa-spinner fa-spin"}):_vm._e(),_vm._v(" "+_vm._s(_vm.purchasesInvoice.id ? _vm.$t("PURCHASES_INVOICES.EDIT_PURCHASES_INVOICE") : _vm.$t("PURCHASES_INVOICES.ADD_PURCHASES_INVOICE"))+" ")])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }