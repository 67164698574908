<template>
  <div class="elite-tabs-wrapper-content">
    <div class="title-header">
      <div class="row">
        <div class="col-6">
          <h3>{{ purchasesInvoice.name }}</h3>
        </div>

        <div class="col-6 text-right">
          <base-button
            class="btn-outline-danger"
            icon
            size="sm"
            v-if="
              purchasesInvoice.status === INVOICE_STATUS_DRAFT &&
              $currentUserCan($permissions.PERM_DELETE_PURCHASES_INVOICES)
            "
            @click="deletePurchasesInvoice"
          >
            <span class="btn-inner--icon">
              <i class="far fa-trash-alt"></i>
            </span>
            <span class="btn-inner--text">
              {{ $t("COMMON.DELETE") }}
            </span>
          </base-button>

          <base-button
            class="elite-button add"
            icon
            size="sm"
            v-if="
              purchasesInvoice.status === INVOICE_STATUS_DRAFT &&
              $currentUserCan($permissions.PERM_EDIT_PURCHASES_INVOICES)
            "
            @click="editPurchasesInvoice"
          >
            <span class="btn-inner--icon">
              <i class="far fa-edit"></i>
            </span>
            <span class="btn-inner--text">
              {{ $t("COMMON.EDIT") }}
            </span>
          </base-button>
        </div>
      </div>
    </div>

    <div class="all-infos">
      <div class="all-infos-text">
        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.CREATED_AT") }}</dt>
          <dd class="col-sm-8">
            {{ purchasesInvoice.created_at | moment("LLLL") }}
          </dd>
        </dl>

        <!--<dl class="row">
          <dt class="col-sm-4">
            {{ $t("PURCHASES_INVOICES.EXPIRATION_TIME") }}
          </dt>
          <dd class="col-sm-8">
            {{ purchasesInvoice.expiration_time | moment("LLLL") }}
          </dd>
        </dl>-->
        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.LOCATIONS") }}</dt>
          <dd class="col-sm-8">
            <location :purchasesInvoice="purchasesInvoice.id" />
          </dd>
        </dl>
        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.ORGANIZATION") }}</dt>
          <dd class="col-sm-8">
            <organization :organization="purchasesInvoice.organization" />
          </dd>
        </dl>

        <!--<dl class="row" v-if="purchasesInvoice.purchasesOrder">
          <dt class="col-sm-4">{{ $t("COMMON.PURCHASES_ORDER") }}</dt>
          <dd class="col-sm-8">
            <object-link :object="purchasesInvoice.purchasesOrder" />
          </dd>
        </dl>-->

        <!--<dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.ISSUER") }}</dt>
          <dd class="col-sm-8">
            <object-link :object="purchasesInvoice.issuer" />
          </dd>
        </dl>-->

        <!--<dl class="row" v-if="purchasesInvoice.destinationWarehouse">
          <dt class="col-sm-4">{{ $t("COMMON.WAREHOUSE") }}</dt>
          <dd class="col-sm-8">
            <object-link :object="purchasesInvoice.destinationWarehouse" />
          </dd>
        </dl>-->

        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.STATUS") }}</dt>
          <dd class="col-sm-8">
            <purchases-invoice-status-badge
              :purchasesInvoice="purchasesInvoice"
            />
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.TOTAL") }}</dt>
          <dd class="col-sm-8" v-if="purchasesInvoice.pricing">
            <span> {{ $formatCurrency(purchasesInvoice.pricing.total) }} </span>
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.TOTAL_PAIED") }}</dt>
          <dd class="col-sm-8">
            <span>
              {{
                $formatCurrency(
                  purchasesInvoice.total_paied
                    ? purchasesInvoice.total_paied
                    : 0
                )
              }}
            </span>
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4" v-if="purchasesInvoice.excerpt">
            {{ $t("COMMON.NOTE") }}
          </dt>
          <dd class="col-sm-8" v-if="purchasesInvoice.excerpt">
            <div v-html="purchasesInvoice.excerpt"></div>
          </dd>
        </dl>
      </div>
    </div>
  </div>
</template>

<script>
import PurchasesInvoiceStatusBadge from "./PurchasesInvoiceStatusBadge.vue";
import { INVOICE_STATUS_DRAFT } from "@/constants/invoices";
import Location from "@/components/Location";

export default {
  name: "purchases-invoice-view-global",

  components: {
    PurchasesInvoiceStatusBadge,
    Location,
  },

  props: ["purchasesInvoice"],

  data() {
    return {
      INVOICE_STATUS_DRAFT,
    };
  },

  computed: {},

  created() {},

  methods: {
    editPurchasesInvoice() {
      this.$emit("onEditPurchasesInvoice", this.purchasesInvoice);
    },
    deletePurchasesInvoice() {
      this.$emit("onDeletePurchasesInvoice", this.purchasesInvoice);
    },
  },

  mounted() {},

  watch: {
    purchasesInvoice(purchasesInvoice) {},
  },
};
</script>
